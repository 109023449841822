import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

import Container from '@material-ui/core/Container'

export const PrivacyPageTemplate = ({
  title,
  content,
  contentComponent,
  description,
  helmet,
  PostContent = contentComponent || Content
}) => (
  <>
  {helmet || ''}
    <section className="Section">
      <Container>
        <div className="PageTitle-section">
          <h3 className="TextCenter">
            {title}<span>{description}</span>
          </h3>
        </div>
        <div className="ContentDetail-main">
          <div className="ContentDetailPostContent-markdown">
            <PostContent content={content} />
          </div>
        </div>
      </Container>
    </section>
  </>
)

PrivacyPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
}

const PrivacyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <PrivacyPageTemplate
        helmet={
          <Helmet titleTemplate="%s">
            <title>{frontmatter.title}</title>
            <meta
              name="description"
              content={frontmatter.description}
            />
          </Helmet>
        }
        title={frontmatter.title}
        description={frontmatter.description}
        content={data.markdownRemark.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

PrivacyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PrivacyPage

export const privacyPageQuery = graphql`
  query PrivacyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
